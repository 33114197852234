import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logoWhite from "../../images/logo-white.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/headers/languageSwitcher.js";

const StyledHeader = styled.header`
  ${tw`flex left-[5%] mt-2`}
  ${tw`fixed w-[91%] h-20 z-50 text-white pt-0`}
  border-radius: 33px;
  background: #17406d;
  box-shadow: 16px 16px 32px #0f2946, -16px -16px 32px #0f2946;
`;

export const NavLinks = tw.div`flex flex-row gap-6 mx-8`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-sm lg:text-base xl:text-base
  font-semibold tracking-wide transition
  min-w-[150px] p-3 text-center border-b-2 border-primary-700 hocus:border-b-2 duration-200 hocus:border-white border-[#1a4a7d] rounded inline-block
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-white text-primary-500 min-w-[200px] inline-block
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  hocus:border-white hocus:border-solid shadow-2xl
`;
export const LogoContainer = tw.div`flex justify-center w-full lg:w-auto`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-light border-b-0 text-xl! hocus:border-0 hocus:shadow-none 
  hocus:bg-transparent`};
  img {
    ${tw`w-10 lg:mr-3 ml-6`}
  }
`;

export const LogoMenuLink = styled(LogoLink)`
  ${tw``}
`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between z-50 overflow-hidden`;
export const NavToggle = tw.button`
  z-50 focus:outline-none transition duration-100 pr-6
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`z-10 fixed top-0 inset-x-0 p-8 border text-center items-center text-white bg-primary-700 opacity-[90] min-h-screen justify-center`}
  ${NavLinks} {
    ${tw`flex flex-col items-center text-sm my-6 lg:text-base xl:text-lg`}
  }
`);
export const LogoNS = tw.div`inline pl-1`;
export const LogoName = tw.p`text-base md:text-xl tracking-[2px] md:tracking-[1.5px] px-2`;
export const LogoSlogan = tw.p`text-xs md:text-sm px-2`;
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-evenly items-center
`;
const AnimatedNavToggle = styled(motion.button)`
  ${tw`z-50 focus:outline-none transition duration-100 relative overflow-hidden mr-4 sm:mr-6 text-primary-700 flex items-center justify-center`};
  position: relative;
  padding: 8px; // Adjust as needed
  background: none;
  border: none;
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: ${(props) =>
      props.className.includes("open")
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 1)"};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease, height 0.3s ease;
    z-index: -1;
  }

  &:hover:before {
    width: 150%; // Adjust to ensure it covers the whole button
    height: 150%;
  }

  svg {
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
  }

  &:hover svg {
    color: ${(props) =>
      props.className.includes("closed")
        ? "rgba(23, 64, 109, 1)"
        : "rgba(23, 64, 109, 1)"}; // You can change this to the desired color
  }
`;

const navToggleVariants = {
  hover: {
    scale: 1.1,
  },
};

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href={`/${lang}/`}>{t("menu.")}</NavLink>
      <NavLink href={`/${lang}/about`}>{t("menu.about")}</NavLink>
      <NavLink href={`/${lang}/services`}>{t("menu.services")}</NavLink>
      <NavLink href={`/${lang}/contact`}>{t("menu.contact")}</NavLink>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href={`/${lang}/get-quote`}>
        {t("menu.get-quota")}
      </PrimaryLink>
    </NavLinks>,
    <LanguageSwitcher key="lang-switcher" />,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoContainer>
      <LogoLink href="/">
        <img src={logoWhite} alt="logo" />
        <LogoNS>
          <LogoName>MAZILU & PARTNERS</LogoName>
          <LogoSlogan>Dedication to value creation</LogoSlogan>
        </LogoNS>
      </LogoLink>
    </LogoContainer>
  );
  const defaultMenuLogo = (
    <LogoContainer>
      <LogoMenuLink href="/">
        <img src={logoWhite} alt="logo" />
        <LogoNS>
          <LogoName>MAZILU & PARTNERS</LogoName>
          <LogoSlogan>Dedication to value creation</LogoSlogan>
        </LogoNS>
      </LogoMenuLink>
    </LogoContainer>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <StyledHeader>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>
      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}>
        <div className="flex-grow" />
        {logoLink}
        <AnimatedNavToggle
          variants={navToggleVariants}
          whileHover="hover"
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6 text-white m-0 p-0" />
          ) : (
            <MenuIcon tw="w-6 h-6 text-white m-0 p-0" />
          )}
        </AnimatedNavToggle>
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}>
          {defaultMenuLogo}
          {links}
        </MobileNavLinks>
      </MobileNavLinksContainer>
    </StyledHeader>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:flex`,
    desktopNavLinks: tw`sm:hidden`,
    mobileNavLinksContainer: tw`sm:flex`,
  },
  md: {
    mobileNavLinks: tw`md:flex`,
    desktopNavLinks: tw`md:hidden`,
    mobileNavLinksContainer: tw`md:flex`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:flex`,
    desktopNavLinks: tw`lg:hidden`,
    mobileNavLinksContainer: tw`lg:flex`,
  },
};
