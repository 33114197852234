import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

const Container = tw.div`flex w-full`;

const SingleColumn = tw.div`mx-auto flex flex-col gap-1 w-full`;

const Content = tw.div`w-full`;
const Description = tw.div`mt-2 text-base leading-loose`;

const List = tw.ul`space-y-4 text-left text-white`
const ListItem = tw.li`flex items-center space-x-3`
const ListSVG = tw.svg`flex-shrink-0 w-6 h-6 text-green-500 m-2`

export default () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SingleColumn>
        <Content>
          <Description>
            <List>
            {t("about.tabs.second.description.info", { returnObjects: true }).map((point, index) => (
                        <ListItem key={index}><ListSVG aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                     </ListSVG>{point}</ListItem>
                    ))}
            </List>
           </Description>
        </Content>
      </SingleColumn>
    </Container>
  );
};
