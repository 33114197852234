import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { useTranslation } from "react-i18next";


const Container = tw.div`flex w-full`;
const SingleColumn = tw.div`mx-auto flex flex-row w-full`;
const Content = tw.div`w-full flex justify-center md:justify-start px-2`;
const Description = tw.div`mt-2 text-base leading-loose text-left`;
const Subtitle = tw.p`text-base`
const StyledSpan = tw.p`first-letter:text-3xl first-letter:font-extrabold font-semibold text-center`;

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SingleColumn>
        <Content>
          <Description>
            <StyledSpan>{t("about.tabs.third.description.description.first.key")}</StyledSpan>
            <Subtitle>{t("about.tabs.third.description.description.first.value")}</Subtitle>
            <StyledSpan>{t("about.tabs.third.description.description.second.key")}</StyledSpan>
            <Subtitle>{t("about.tabs.third.description.description.second.value")}</Subtitle>
            <StyledSpan>{t("about.tabs.third.description.description.third.key")}</StyledSpan>
            <Subtitle>{t("about.tabs.third.description.description.third.value")}</Subtitle>
            <StyledSpan>{t("about.tabs.third.description.description.fourth.key")}</StyledSpan>
            <Subtitle>{t("about.tabs.third.description.description.fourth.value")}</Subtitle>
           </Description>
        </Content>
      </SingleColumn>
    </Container>
  );
};
