import React, { useState, useEffect } from "react";
import anime from "animejs";
import tw from "twin.macro";

const Dot = tw.div`
  cursor-pointer
  rounded-full
  w-3 h-3
  bg-white
`;

const getGridSize = (width) => {
  if (width >= 1440) return { width: 10, height: 10 }; // 2xl
  if (width >= 1280) return { width: 10, height: 10 }; // xl
  if (width >= 1024) return { width: 10, height: 10 }; // lg
  if (width >= 768) return { width: 10, height: 10 }; // md
  return { width: 10, height: 10 }; // sm or smaller
};

const DotGrid = ({ gridRef, gridSize }) => {
  const { width: GRID_WIDTH, height: GRID_HEIGHT } = gridSize;

  const handleDotClick = (e) => {
    // Target only the dots within the specific grid that was clicked
    anime({
      targets: gridRef.current.querySelectorAll(".dot"),
      scale: [
        { value: 1.35, easing: "easeOutSine", duration: 250 },
        { value: 1, easing: "easeInOutQuad", duration: 500 },
      ],
      translateY: [
        { value: -15, easing: "easeOutSine", duration: 250 },
        { value: 0, easing: "easeInOutQuad", duration: 500 },
      ],
      delay: anime.stagger(100, {
        grid: [GRID_WIDTH, GRID_HEIGHT],
        from: e.target.dataset.index,
      }),
    });
  };

  const dots = [];
  let index = 0;
  for (let i = 0; i < GRID_WIDTH; i++) {
    for (let j = 0; j < GRID_HEIGHT; j++) {
      dots.push(
        <Dot
          onClick={handleDotClick}
          className="dot"
          data-index={index}
          key={index}
        />
      );
      index++;
    }
  }

  return (
    <div
      ref={gridRef}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${GRID_WIDTH}, 1fr)`,
        gridGap: "8px",
      }}
    >
      {dots}
    </div>
  );
};

const WaterDropGrid = () => {
  const gridRef = React.useRef(null);
  const [gridSize, setGridSize] = useState(getGridSize(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setGridSize(getGridSize(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative grid place-content-center overflow-hidden">
      <DotGrid gridRef={gridRef} gridSize={gridSize} />
    </div>
  );
};

export default WaterDropGrid;
