import React, { useEffect } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import { useParams } from "react-router-dom";
import i18n from "i18next";
export default () => {
  const { lang } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <AnimationRevealPage disabled>
      <Hero />
    </AnimationRevealPage>
  );
};
