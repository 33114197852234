import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Tab from "../misc/Tab.js"; // renamed the import to avoid conflict
import { motion, AnimatePresence } from "framer-motion";
import WaterDropGrid from "../misc/waterDropGrid.js";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useParams, useNavigate } from "react-router-dom";

const Container = tw.div`relative text-white min-h-full w-screen py-6 pt-32 z-0 bg-cover bg-no-repeat bg-center mb-10 px-4`; 
const SingleColumn = tw.div`flex flex-col items-center mx-auto text-white min-h-full `;
const BackGround = styled.div`
  ${tw`relative w-[80%] rounded-4xl z-10 pt-10 pb-10 backdrop-blur-sm`}
  background-color: rgba(37, 99, 235, 0.25);
  box-shadow:  20px -20px 50px #143961,
             -20px 20px 50px #1a4779;
`;
const HeadingInfoContainer = tw.div`flex flex-col items-center w-full drop-shadow-2xl py-2 z-20 border-b-2 rounded`;
const HeadingDescription = tw.p`px-2 py-2 font-medium text-gray-200 text-center w-full drop-shadow-2xl z-20 text-xl`;

const Content = tw.div`flex flex-col lg:flex-row w-auto drop-shadow-xl py-2 lg:py-0 z-20 min-h-[65vh]`;

const Card = styled(motion.div)((props) => [
  tw`py-4 lg:py-0 flex flex-wrap items-center w-full lg:min-h-[800px] justify-center z-20 lg:pl-20 lg:pr-40`,
  props.reversed ? tw`flex-row-reverse w-auto` : "flex-row w-auto",
]);

const Details = tw.div`md:mt-0 md:max-w-lg mx-2 sm:mx-4 md:mx-2 lg:mx-4 drop-shadow-2xl py-1`;
const Subtitle = tw.div`lg:tracking-wide text-center font-light text-base lg:text-lg lg:py-4 py-2`;
const Title = tw.h4`text-3xl font-light text-center py-1`;
const Description = tw.p`mt-2 text-base lg:text-base leading-loose tracking-wide text-center py-2`;
const TabContainer = tw.div`flex flex-col self-center lg:flex-col justify-center gap-1 md:gap-2 lg:justify-center lg:w-1/3 lg:pl-12`;
const HeadingSpan = styled.span`
  ${tw``}
`;
const WaterDropGridContainer = styled.div`
  ${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-0 lg:bottom-0 right-0 lg:right-0 z-40`}
`;
const WaterDropGridContainer2 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-1/2 lg:bottom-1/2 right-0 lg:right-0 z-40`}
`;
/* const WaterDropGridContainer3 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] top-[85%] lg:top-[85%] left-[5%] lg:left-[5%] z-40`}
`; */
const List = tw.ul`space-y-4 text-left text-white`
const ListItem = tw.li`flex items-center space-x-3`
const ListSVG = tw.svg`flex-shrink-0 w-6 h-6 text-green-500 m-2`
 
const ServicesComponents = ({ activeTab }) => {
  const navigate = useNavigate();
  const tabLinks = [
    "audit-and-assurance",
    "accounting-finance-and-tax",
    "business-risk",
    "transactions",
    "business-transformation",
    "hr-and-administrative-assistance"
  ];
  const initialTabIndex = tabLinks.indexOf(activeTab);
   
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex !== -1 ? initialTabIndex : 0);
  const prevActiveTabIndex = useRef(activeTabIndex);
  const { lang } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  useEffect(() => {
    prevActiveTabIndex.current = activeTabIndex;
  }, [activeTabIndex]);
  const services = [
    {
      image: "",
      title: t("services.tabs.first.name"),
      subtitle: t("services.tabs.first.description.subtitle"),
      description: t("services.tabs.first.description.text", { returnObjects: true }),
    },
    {
      image: "",
      title: t("services.tabs.second.name"),
      subtitle: t("services.tabs.second.description.subtitle"),
      description: t("services.tabs.second.description.text", { returnObjects: true }),
    },
    {
      image: "",
      title: t("services.tabs.third.name"),
      subtitle: t("services.tabs.third.description.subtitle"),
      description: t("services.tabs.third.description.text", { returnObjects: true }),
    },
    {
      image: "",
      title: t("services.tabs.fourth.name"),
      subtitle: t("services.tabs.fourth.description.subtitle"),
      description: t("services.tabs.fourth.description.text", { returnObjects: true }),
    },
    {
      image: "",
      title: t("services.tabs.fifth.name"),
      subtitle: t("services.tabs.fifth.description.subtitle"),
      description: t("services.tabs.fifth.description.text", { returnObjects: true }),
    },
    {
      image: "",
      title: t("services.tabs.sixth.name"),
      subtitle: t("services.tabs.sixth.description.subtitle"),
      description: t("services.tabs.sixth.description.text", { returnObjects: true }),
    },
  ];
  const changeTab = (index) => {
    setActiveTabIndex(index);
    // Update the URL with the appropriate tab link
    navigate(`/${lang}/services/${tabLinks[index]}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []);
  return (
    <Container>
      
      <SingleColumn>
        <BackGround>
        <WaterDropGridContainer>
            <WaterDropGrid />
      </WaterDropGridContainer>
        <WaterDropGridContainer2>
            <WaterDropGrid />
      </WaterDropGridContainer2>
      {/* <WaterDropGridContainer3>
            <WaterDropGrid />
      </WaterDropGridContainer3> */}
        <HeadingInfoContainer>
          <HeadingTitle>{t("services.head.title")}</HeadingTitle>
          <HeadingDescription>
          {t("services.head.subtitle.text1")} <HeadingSpan>{t("services.head.subtitle.text2")}</HeadingSpan> {t("services.head.subtitle.text3")}
          </HeadingDescription>
        </HeadingInfoContainer>
        <Content>
        <TabContainer>
        {services.map((service, index) => (
          <Tab
          key={index}
          onClick={() => changeTab(index)}
          active={activeTabIndex === index}
          animate={{
            backgroundColor: activeTabIndex === index ? "rgba(255, 255, 255, 1)" : "transparent",
            color: activeTabIndex === index ? "rgba(23, 64, 109, 1)" : "rgba(255, 255, 255, 1)",
          }}
          transition={{ duration: 0.9 }}
        >
          {service.title}
        </Tab>
        ))}
</TabContainer>

          <AnimatePresence mode='wait'>
            <Card
              key={activeTabIndex}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              {/* <Image imageSrc={services[activeTab].image} /> */}
              <Details>
                <Subtitle>{services[activeTabIndex].subtitle}</Subtitle>
                <Title>{services[activeTabIndex].title}</Title>
                <Description>
  {Array.isArray(services[activeTabIndex].description) ? (
    <List>
      {services[activeTabIndex].description.map((item, index) => (
        <ListItem key={index}><ListSVG aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
     </ListSVG> {item}</ListItem>
      ))}
    </List>
  ) : (
    services[activeTabIndex].description
  )}
</Description>

              </Details>
            </Card>
          </AnimatePresence>
        </Content>
        </BackGround>
      </SingleColumn>
    </Container>
  );
};

export default ServicesComponents;
