import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import axios from "axios";
import logoWhite from "../../images/logo-white.svg";

const FormContainer = styled.div`
  ${tw`w-[100%] md:w-[90%] mx-auto py-10 sm:py-12 md:py-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4 w-[100%]`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const NavLink = tw.a`
  text-sm lg:text-base xl:text-base
  font-semibold tracking-wide transition
  min-w-[150px] p-3 text-center border-b-2 border-primary-700 hocus:border-b-2 duration-200 hocus:border-white border-[#1a4a7d] rounded inline-block
`;
const LogoContainer = tw.div`flex justify-center w-full lg:w-auto`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-light border-b-0 text-xl! hocus:border-0 hocus:shadow-none 
  hocus:bg-transparent`};
  img {
    ${tw`w-10 lg:mr-3 ml-6`}
  }
`;
const LogoNS = tw.div`inline pl-1`;
const LogoName = tw.p`text-base md:text-xl tracking-[2px] md:tracking-[1.5px] px-2`;
const LogoSlogan = tw.p`text-xs md:text-sm px-2`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between min-w-full`;
const Column = tw.div`sm:w-[49%] w-full flex flex-col`;
const ThankYouColumn = tw.div`w-full flex flex-col justify-center min-h-[300px] items-center`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const NumberLabel = tw.div`absolute bottom-[100%] left-0 tracking-wide font-semibold text-sm`;
const NumberInput = tw.input``;
const NumbersContainer = styled.div`
${tw`flex flex-row justify-between gap-5`}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
const Number = tw.div`relative flex flex-col w-[97%]`;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded font-bold tracking-wide shadow-2xl uppercase text-sm transition duration-300 transform hover:text-white border-b-2 hover:border-white hover:bg-primary-700`;

const ErrorContainer = styled.div`
  ${tw`absolute bg-red-500 text-white py-1 px-2 text-xs rounded mt-1`}
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
export default () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Define an initial state for form data
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    company: "",
    industry: "",
    totalRevenue: "",
    totalAssets: "",
    companySize: "",
    needs: "",
    message: "",
  };
  
  // Define an initial state for form errors
  const initialFormErrors = {
    name: "",
    email: "",
    phone: "",
    company: "",
    industry: "",
    totalRevenue: "",
    totalAssets: "",
    companySize: "",
    needs: "",
    message: "",
  };
  
  // Initialize form data state with the initial state
  const [formData, setFormData] = useState(initialFormData);
  
  // Initialize form errors state with the initial state
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Update form data state with the new value
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  // Handle input blur (validation)
  const handleBlur = (e) => {
    const { name, value } = e.target;
  
    // Create a copy of the formErrors object
    const newFormErrors = { ...formErrors };
  
    // Add your validation logic here for each field
    if (name === "name" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.name.error");
    } else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      newFormErrors[name] = t("get-quota.form.email.error");
    } else if (name === "phone" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.phone.error");
    } else if (name === "company" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.company.error");
    } else if (name === "industry" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.industry.error");
    } else if (name === "totalRevenue" && isNaN(value)) {
      newFormErrors[name] = t("get-quota.form.totalRevenue.error");
    } else if (name === "totalAssets" && isNaN(value)) {
      newFormErrors[name] = t("get-quota.form.totalAssets.error");
    } else if (name === "companySize" && isNaN(value)) {
      newFormErrors[name] = t("get-quota.form.companySize.error");
    } else if (name === "needs" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.needs.error");
    } else if (name === "message" && value.trim() === "") {
      newFormErrors[name] = t("get-quota.form.message.error");
    } else {
      // Clear the error if the input is valid
      delete newFormErrors[name];
    }
  
    // Update the formErrors state
    setFormErrors(newFormErrors);
  };
  
// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate empty or invalid fields before submitting
  const newFormErrors = {};
  for (const [key, value] of Object.entries(formData)) {
    if (!value.trim()) {
      newFormErrors[key] = t(`get-quota.form.${key}.error`);
    }
  }

  setFormErrors(newFormErrors);

  if (Object.keys(newFormErrors).length === 0) {
    try {
      const response = await axios.post("/send/submit-quote.php", formData);
      if (response.status === 200) {
        setIsSubmitted(true);
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("There was an issue submitting the form:", error);
    }
  } else {
    console.log("Form has errors, not submitting");
  }
};


  if (isSubmitted) {
    return <FormContainer><div tw="mx-auto max-w-4xl justify-center">
    <ThankYouColumn>{t(`get-quota.form.thankYou`)}</ThankYouColumn></div><LogoContainer>
      <LogoLink href="/">
        <img src={logoWhite} alt="logo" />
        <LogoNS>
          <LogoName>MAZILU & PARTNERS</LogoName>
          <LogoSlogan>Dedication to value creation</LogoSlogan>
        </LogoNS>
      </LogoLink>
    </LogoContainer></FormContainer>;
  }

  return (
    <FormContainer>
      <div tw="mx-auto px-6 md:px-12">
        <form onSubmit={handleSubmit}>
          <TwoColumn>
            <Column>
              <InputContainer>
                <Label htmlFor="name-input">{t("get-quota.form.name.text")}</Label>
                <Input
                  id="name-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.name.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.name}>
                  {formErrors.name}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="email-input">{t("get-quota.form.email.text")}</Label>
                <Input
                  id="email-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.email.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.email}>
                  {formErrors.email}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="phone-input">{t("get-quota.form.phone.text")}</Label>
                <Input
                  id="phone-input"
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.phone.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.phone}>
                  {formErrors.phone}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="company-input">{t("get-quota.form.company.text")}</Label>
                <Input
                  id="company-input"
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.company.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.company}>
                  {formErrors.company}
                </ErrorContainer>
                
              </InputContainer>
              <InputContainer>
                <Label htmlFor="industry-input">{t("get-quota.form.industry.text")}</Label>
                <Input
                  id="industry-input"
                  type="text"
                  name="industry"
                  value={formData.industry}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.industry.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.industry}>
                  {formErrors.industry}
                </ErrorContainer>
              </InputContainer>
            </Column>
            <Column>
              <InputContainer>
                <NumbersContainer>
                  <Number>
                    <NumberLabel htmlFor="totalRevenue-input">{t("get-quota.form.totalRevenue.text")}</NumberLabel>
                    <NumberInput
                      id="totalRevenue-input"
                      type="number"
                      name="totalRevenue"
                      value={formData.totalRevenue}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder={t("get-quota.form.totalRevenue.placeholder")}
                    />
                    <ErrorContainer visible={!!formErrors.totalRevenue}>
                      {formErrors.totalRevenue}
                    </ErrorContainer>
                  </Number>
                  <Number>
                    <NumberLabel htmlFor="totalAssets-input">{t("get-quota.form.totalAssets.text")}</NumberLabel>
                    <NumberInput
                      id="totalAssets-input"
                      type="number"
                      name="totalAssets"
                      value={formData.totalAssets}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder={t("get-quota.form.totalAssets.placeholder")}
                    />
                    <ErrorContainer visible={!!formErrors.totalAssets}>
                      {formErrors.totalAssets}
                    </ErrorContainer>
                  </Number>
                  <Number>
                    <NumberLabel htmlFor="companySize-input">{t("get-quota.form.companySize.text")}</NumberLabel>
                    <NumberInput
                      id="companySize-input"
                      type="number"
                      name="companySize"
                      value={formData.companySize}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder={t("get-quota.form.companySize.placeholder")}
                    />
                    <ErrorContainer visible={!!formErrors.companySize}>
                      {formErrors.companySize}
                    </ErrorContainer>
                  </Number>
                </NumbersContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="needs-input">{t("get-quota.form.needs.text")}</Label>
                <Input
                  id="needs-input"
                  type="text"
                  name="needs"
                  value={formData.needs}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.needs.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.needs}>
                  {formErrors.needs}
                </ErrorContainer>
              </InputContainer>
              <InputContainer tw="flex-1">
                <Label htmlFor="message-input">{t("get-quota.form.message.text")}</Label>
                <TextArea
                  id="message-input"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder={t("get-quota.form.message.placeholder")}
                />
                <ErrorContainer visible={!!formErrors.message}>
                  {formErrors.message}
                </ErrorContainer>
              </InputContainer>
            </Column>
          </TwoColumn>
  
          <SubmitButton type="submit" value={t("get-quota.form.button")} onClick={() => console.log("Button Clicked")}>
            {t("get-quota.form.button")}
          </SubmitButton>

        </form>
      </div>
    </FormContainer>
  );
};
