import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import i18n from "i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import EnglishFlag from '../../images/united-kingdom.png';
import RomanianFlag from '../../images/romania.png';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../helpers/LanguageContext.js";
const LanguageSwitcherContainer = tw.div`flex flex-row items-center justify-center md:relative py-10 md:py-0`;

const CurrentFlag = tw.img`h-8 w-8 cursor-pointer`; 

const OtherLanguageFlag = styled.img`
  ${tw`h-8 w-8 absolute left-0 cursor-pointer transition-opacity duration-500`}
  &:hover {
    ${tw`opacity-100`}
  }
`;
const LanguageFlagContainer = styled.div`
  ${tw`relative cursor-pointer`}
`;
const LanguageSwitcher = () => {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage } = useLanguage();
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const switchLanguage = (newLang) => {
    changeLanguage(newLang);
    const translatedRoutes = {
      "terms-and-conditions": t("routes.terms-and-conditions"),
      "return-policy": t("routes.return-policy"),
      "free-report": t("routes.free-report"),
      "get-a-quota": t("routes.get-a-quota"),
    };

    const newPathname = location.pathname
      .split("/")
      .map((segment, index) => {
        if (index === 1) {
          return newLang;
        } else if (index === 2 && translatedRoutes[segment]) {
          return translatedRoutes[segment];
        }
        return segment;
      })
      .join("/");

    navigate(newPathname);
  };

  return (
    <LanguageSwitcherContainer>
      <LanguageFlagContainer 
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {i18n.language === 'en' ? (
          <>
            <CurrentFlag src={EnglishFlag} alt="English" />
            {hovered && <OtherLanguageFlag src={RomanianFlag} alt="Romanian" onClick={() => switchLanguage('ro')} />}
          </>
        ) : (
          <>
            <CurrentFlag src={RomanianFlag} alt="Romanian" />
            {hovered && <OtherLanguageFlag src={EnglishFlag} alt="English" onClick={() => switchLanguage('en')} />}
          </>
        )}
      </LanguageFlagContainer>
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcher;