import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { useTranslation } from "react-i18next";


const Container = tw.div`flex w-full`;
const SingleColumn = tw.div`mx-auto flex flex-row w-full`;
const Content = tw.div`w-full flex justify-center lg:justify-start`;
const Description = tw.p`mt-2 text-base leading-loose text-left`;
const Subtitle = tw.p`text-base`
const StyledSpan = tw.p`first-letter:text-3xl first-letter:font-bold pl-10`;

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SingleColumn>
        <Content>
          <Description>
            <Subtitle>{t("about.tabs.first.description.components.virtue.heading")}</Subtitle>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.v")}</StyledSpan>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.i")}</StyledSpan>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.r")}</StyledSpan>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.t")}</StyledSpan>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.u")}</StyledSpan>
            <StyledSpan>{t("about.tabs.first.description.components.virtue.e")}</StyledSpan>
           </Description>
        </Content>
      </SingleColumn>
    </Container>
  );
};
