import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnDark.js";
import Services from "components/features/ServicesComponents.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import AuditAssurancec from "../images/section-2.webp";
import i18n from "i18next";
import { useParams } from "react-router-dom";

const StyledHeader = styled(Header)`
  ${tw`w-full text-white order-first`}
  
  `;

const Container = styled.div`
  ${tw`relative text-white font-sans min-h-full w-screen z-20 bg-cover bg-no-repeat bg-center`}
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${(props) => props.image});
`;

export default () => {
  const { lang } = useParams();
  const { tab } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <AnimationRevealPage disabled={true}>
      <Container image={AuditAssurancec}>
        <StyledHeader />
        <Services activeTab={tab} tw="z-10" />
        <Footer tw="z-10" />
      </Container>
    </AnimationRevealPage>
  );
};