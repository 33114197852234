import React, { useEffect, useRef, useState, useMemo  } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as SqCheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as CreditIcon } from "feather-icons/dist/icons/credit-card.svg";
import { ReactComponent as ZapIcon } from "feather-icons/dist/icons/zap.svg";
import { ReactComponent as SettingsIcon } from "feather-icons/dist/icons/settings.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as AlertIcon } from "feather-icons/dist/icons/alert-triangle.svg";


/* import WaterDropGrid from "../misc/waterDropGrid.js";
 */

const StyledLink = styled(Link)`
  ${tw` text-white  no-underline hover:transition-all transform ease-in-out delay-150  motion-reduce:transition-none motion-reduce:hover:transform-none`}
`;
const ContainerInfo = styled.div`
${tw`grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 xl:grid-cols-3 xl:grid-rows-2 gap-16 text-white font-sans z-0 bg-cover bg-no-repeat bg-center mt-10 mb-10 backdrop-blur-xl`}
align-items: stretch;
`;
const SingleColumn = styled(motion.div)`
  ${tw`flex flex-1 mx-auto text-white w-full z-40`}
`;
const BackGround = styled.div`
  ${tw`relative w-[80%] rounded-4xl z-10 hover:transition-all delay-[100ms] overflow-hidden`}
  background-color: rgba(37, 99, 235, 0.25);
  box-shadow: 20px -20px 50px #143961, -20px 20px 50px #1a4779;
  width: 450px;  // Fixed width for desktop
  height: 350px;  // Fixed height for desktop

  @media (max-width: 768px) {  // Adjust the breakpoint as needed
    width: 300px;  // Width for mobile
    height: 300px;  // Height for mobile
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(23, 64, 109, 1);
    border-radius: inherit;
    z-index: -1;
    transform-origin: center;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }

  :hover::before {
    transform: scale(1);
  }
`;
const Content = tw.div`flex flex-col lg:flex-row w-full drop-shadow-xl z-20 text-white py-2`;
const Card = styled(motion.div)((props) => [
    tw`py-4 lg:py-6 flex flex-wrap w-full justify-center z-20 flex-col h-full`,
    props.reversed ? tw`flex-row-reverse w-auto` : "flex-row w-auto",
  ]);
  const Details = styled.div`
  ${tw`relative md:mt-0 mx-2 sm:mx-4 md:mx-2 lg:mx-4 drop-shadow-2xl py-1 flex-grow flex flex-col`}
`;
const Title = tw.div`flex flex-row justify-center gap-2`;

const StyledSqCheckIcon = styled(SqCheckIcon)`
width: 3rem;
height: 3rem;
color: #ffffff;
margin-top: .2rem;
`;
const StyledTrendingIcon = styled(TrendingIcon)`
  width: 3rem;
  height: 3rem;
  color: #ffffff;
  margin-top: .2rem;
`;
const StyledAlertIcon = styled(AlertIcon)`
width: 3rem;
height: 3rem;
color: #ffffff;
margin-top: .2rem;
`;
const StyledCreditIcon = styled(CreditIcon)`
width: 3rem;
height: 3rem;
color: #ffffff;
margin-top: .2rem;
`;
const StyledZapIcon = styled(ZapIcon)`
width: 3rem;
height: 3rem;
color: #ffffff;
margin-top: .2rem;
`;
const StyledSettingsIcon = styled(SettingsIcon)`
width: 3rem;
height: 3rem;
color: #ffffff;
margin-top: .2rem;
`;
const ContentWrapper = styled.div`
  ${tw`flex flex-col justify-between h-full z-30`}
`;
const TitleSpan = tw.h4`text-xl font-bold md:text-3xl md:font-semibold text-center py-1`;
const Description = styled.div`
  ${tw`mt-2 text-sm lg:text-base leading-loose tracking-wide text-justify py-2 relative`}
  line-height: 2rem;  // Adjust as needed
  --max-lines: 4;  // Number of lines you want to show
  position: relative;
  max-height: calc(1.5rem * var(--max-lines));  // Adjust line height as needed
  overflow: hidden;
`;
const TextWrapper = styled.span`
  --max-lines: 4;
  display: block;
  position: relative;
  max-height: calc(1.5rem * var(--max-lines));
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1.2rem;
`;
const Ellipsis = styled.span`
  position: absolute;
  bottom: 0;
  right: 2%;
  color: white;
  z-index: 10;
  width: 1rem;
  text-align: right;
`;

const ReadMore = styled.div`
  ${tw`absolute bottom-[5%] right-[10%] text-white font-bold underline self-end`}
`;
export default () => {
    const { t, i18n  } = useTranslation();
    const lang = i18n.language;

    const pages = [
      "/services/audit-and-assurance",
      "/services/accounting-finance-and-tax",
      "/services/business-risk",
      "/services/transactions",
      "/services/business-transformation",
      "/services/hr-and-administrative-assistance"
    ];
    const tab = useMemo(() => [
      t("services.tabs.first.name"),
      t("services.tabs.second.name"),
      t("services.tabs.third.name"),
      t("services.tabs.fourth.name"),
      t("services.tabs.fifth.name"),
      t("services.tabs.sixth.name")
    ], [t]);
    const phrases = useMemo(() => [
      t("services.tabs.first.description.subtitle"),
      t("services.tabs.second.description.subtitle"),
      t("services.tabs.third.description.subtitle"),
      t("services.tabs.fourth.description.subtitle"),
      t("services.tabs.fifth.description.subtitle"),
      t("services.tabs.sixth.description.subtitle")
    ], [t]);
    
    const icons = [
        <StyledSqCheckIcon />,
        <StyledTrendingIcon />,
        <StyledAlertIcon />,
        <StyledCreditIcon />,
        <StyledZapIcon />,
        <StyledSettingsIcon />
    ];
    const [isOverflowing, setIsOverflowing] = useState(new Array(tab.length).fill(false));
  
  const descriptionRefs = useRef([]);
  useEffect(() => {
    const newIsOverflowing = descriptionRefs.current.map((element, index) => {
      if (element) {
        return element.scrollHeight > element.clientHeight;
      }
      return false;
    });
    setIsOverflowing(newIsOverflowing);
  }, [phrases]);
    return (
        <>
        <ContainerInfo>
          {tab.map((item, index) => (
            <SingleColumn key={index} whileHover={{ scale: 1.1 }}>
                <AnimatePresence disabled>
              <BackGround>
                <Content>
                    <Card
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Details>
                        <ContentWrapper>
                        <Title>
                          {icons[index]}
                          <TitleSpan>{item}</TitleSpan>
                        </Title>
                        <Description className={isOverflowing[index] ? 'overflowing' : 'not-overflowing'}>
                          <div style={{ position: 'relative' }}>
                            <TextWrapper ref={el => (descriptionRefs.current[index] = el)}>
                              {phrases[index]}
                            </TextWrapper>
                            {isOverflowing[index] && <Ellipsis>...</Ellipsis>}
                          </div>
                        </Description>
                        </ContentWrapper>
                      </Details>
                      
                    </Card>
                </Content>
                <ReadMore>
                          <StyledLink to={`/${lang}${pages[index]}`}>
                          {t("home.read-more")}
                          </StyledLink>
                        </ReadMore>
              </BackGround>
              </AnimatePresence>
            </SingleColumn>
          ))}
        </ContainerInfo>
      </>
    );
  };
  