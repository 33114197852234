import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Header from "../headers/light.js";
import Section1 from "../../images/section-1.webp";
import Section2 from "../../images/section-2.webp";
import Section3 from "../../images/section-3.webp";
import { Link } from "react-router-dom";
import HomeAboutComponents from "components/features/HomeAboutComponents.js";
import HomeServicesComponents from "components/features/HomeServicesComponents.js";
import HomeContactComponents from "components/features/HomeContactComponents.js";

const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover min-h-screen`}
  background-image: url(${(props) => props.image});
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-[0.65] `;
const HeroContainer = styled.div`
  ${tw`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col justify-center items-center backdrop-blur-sm`}
  padding-top: ${(props) => (props.py ? "2rem" : "7rem")};
  padding-bottom: ${(props) => (props.py ? "2rem" : "5rem")};
`;

const Paragraph = tw.p`text-base text-white text-center py-4 px-2 mx-2`;

const StyledHeader = styled(Header)`
  ${tw`text-white`}
`;

const StyledLink = styled(Link)`
  ${tw`text-2xl font-black text-center text-white sm:text-3xl lg:text-4xl xl:text-5xl no-underline hover:transition-all transform ease-in-out delay-150 hover:text-primary-700 motion-reduce:transition-none motion-reduce:hover:transform-none`}
`;

const CompContainer = tw.div`relative px-10`;

export default () => {
  const { t, i18n  } = useTranslation();
  const lang = i18n.language;

  const sections = [
    { image: Section1 },
    { image: Section2 },
    { image: Section3 },
  ];
  const pages = [
    "/about",
    "/services",
    "/contact"
  ];
  const slogans = [
    t("home.about-us.menu"),
    t("home.services.menu"),
    t("home.contact-us.menu"),
  ];
  const phrases = [
    t("home.about-us.text"),
    t("home.services.text"),
    t("home.contact-us.text"),
  ]

  const HomeComponents = [
    <HomeAboutComponents />,
    <HomeServicesComponents />,
    <HomeContactComponents />
  ]

  return (
    <>
      <StyledHeader />
      {sections.map((section, index) => (
        <Container image={section.image} key={index}>
          <OpacityOverlay />
          <HeroContainer py={index === 1}>
            <StyledLink to={`/${lang}${pages[index]}`}>
              {slogans[index]}
            </StyledLink>
            <Paragraph>{phrases[index]}</Paragraph>
            <CompContainer>{HomeComponents[index]}</CompContainer>
          </HeroContainer>
        </Container>
      ))}
    </>
  );
};
