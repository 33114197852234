import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import axios from "axios";
import logoWhite from "../../images/logo-white.svg";

const FormContainer = styled.div`
  ${tw`w-full mx-auto min-w-full p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const CustomFileInput = styled.div`
  position: relative;
  display: inline-block;
  padding-top: 1rem;
  & > input[type="file"] {
    position: absolute;
    top: 0;  // align to the top of the container
    left: 50%;  // center align
    transform: translateX(-50%);  // offset the left alignment for perfect centering
    width: 80%;  // match the width of the span
    height: 40px;  // match the height of the span
    opacity: 0;
    cursor: pointer;
  }

  & > span {
    ${tw`w-full cursor-pointer sm:w-48 px-3 py-3 bg-gray-100 text-primary-500 rounded font-bold tracking-wide shadow-2xl uppercase text-sm transition duration-300 transform hover:text-white border-b-2 hover:border-white hover:bg-primary-700`}
  }
  &:hover > span {
    ${tw`text-white border-white bg-primary-700`}
  }
`;
const NavLink = tw.a`
  text-sm lg:text-base xl:text-base
  font-semibold tracking-wide transition
  min-w-[150px] p-3 text-center border-b-2 border-primary-700 hocus:border-b-2 duration-200 hocus:border-white border-[#1a4a7d] rounded inline-block
`;
const LogoContainer = tw.div`flex justify-center w-full lg:w-auto`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-light border-b-0 text-xl! hocus:border-0 hocus:shadow-none 
  hocus:bg-transparent`};
  img {
    ${tw`w-10 lg:mr-3 ml-6`}
  }
`;
const LogoNS = tw.div`inline pl-1`;
const LogoName = tw.p`text-base md:text-xl tracking-[2px] md:tracking-[1.5px] px-2`;
const LogoSlogan = tw.p`text-xs md:text-sm px-2`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 w-full flex flex-col`;
const ThankYouColumn = tw.div`w-full flex flex-col justify-center min-h-[300px] items-center`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded font-bold tracking-wide shadow-2xl uppercase text-sm transition duration-300 transform hover:text-white border-b-2 hover:border-white hover:bg-primary-700`;

const ErrorContainer = styled.div`
  ${tw`absolute bg-red-500 text-white py-1 px-2 text-xs rounded mt-1`}
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
export default () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    jobTitle: "",
    linkedin: "",
    cv: null,
    message: ""
  });
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && /\.(pdf|doc|docx)$/i.test(file.name)) {
      setFileName(t("contact-us.tabs.second.form.curiculum.fileNamePlaceholder"));
      setFormData({
        ...formData,
        cv: file,
      });
    } else {
      setFormErrors({
        ...formErrors,
        cv: t("contact-us.tabs.second.form.curiculum.fileError"),
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const newFormErrors = { ...formErrors };

    if (name === "name" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.second.form.name.error");
    } else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      newFormErrors[name] = t("contact-us.tabs.second.form.email.error");
    } else if (name === "phone" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.second.form.phone.error");
    } else {
      delete newFormErrors[name];
    }

    setFormErrors(newFormErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit is called");
    console.log("Form data before sending:", formData);
    console.log("Current form errors:", formErrors);
  
    // Validate empty or invalid fields only for required fields before submitting
    const newFormErrors = {};
    const requiredFields = ['name', 'email', 'phone', 'cv'];
  
    for (const field of requiredFields) {
      if (field === 'cv') {
        if (!formData[field]) {
          newFormErrors[field] = t(`contact-us.tabs.second.form.curiculum.error`);
        }
      } else {
        if (!formData[field].trim()) {
          newFormErrors[field] = t(`contact-us.tabs.second.form.${field}.error`);
        }
      }
    }
  
    setFormErrors(newFormErrors);
  
    if (Object.keys(newFormErrors).length === 0 && formData.cv) {
      try {
        console.log("Trying to send data via Axios");
        const formDataObj = new FormData();
        Object.keys(formData).forEach((key) => {
          formDataObj.append(key, formData[key]);
        });
        
        const response = await axios.post("/send/submit-job.php", formDataObj);
        console.log("Response from server:", response);
  
        if (response.status === 200) {
          setIsSubmitted(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            jobTitle: "",
            linkedin: "",
            cv: null,
            message: ""
          });
          setFileName("");
        }
      } catch (error) {
        console.error("There was an issue submitting the form:", error);
      }
    } else {
      console.log("Form has errors, not submitting");
    }
  };

  if (isSubmitted) {
    return <FormContainer><div tw="mx-auto max-w-4xl justify-center">
    <ThankYouColumn>{t("contact-us.tabs.first.form.thankYou")}</ThankYouColumn></div><LogoContainer>
      <LogoLink href="/">
        <img src={logoWhite} alt="logo" />
        <LogoNS>
          <LogoName>MAZILU & PARTNERS</LogoName>
          <LogoSlogan>Dedication to value creation</LogoSlogan>
        </LogoNS>
      </LogoLink>
    </LogoContainer></FormContainer>;
  }

  return (
    <FormContainer>
      <div tw="mx-auto max-w-4xl">
        <form onSubmit={handleSubmit}>
          <TwoColumn>
            <Column>
              <InputContainer>
                <Label htmlFor="name-input">{t("contact-us.tabs.second.form.name.text")}</Label>
                <Input id="name-input" type="text" name="name" placeholder={t("contact-us.tabs.second.form.name.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.name}>
                  {formErrors.name}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="email-input">{t("contact-us.tabs.second.form.email.text")}</Label>
                <Input id="email-input" type="email" name="email" placeholder={t("contact-us.tabs.second.form.email.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.email}>
                  {formErrors.email}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="phone-input">{t("contact-us.tabs.second.form.phone.text")}</Label>
                <Input id="phone-input" type="phone" name="phone" placeholder={t("contact-us.tabs.second.form.phone.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.phone}>
                  {formErrors.phone}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="jobTitle-input">{t("contact-us.tabs.second.form.jobTitle.text")}</Label>
                <Input id="jobTitle-input" type="text" name="jobTitle" placeholder={t("contact-us.tabs.second.form.jobTitle.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.jobTitle}>
                  {formErrors.jobTitle}
                </ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="linkedin-input">{t("contact-us.tabs.second.form.linkedin.text")}</Label>
                <Input id="linkedin-input" type="text" name="linkedin" placeholder={t("contact-us.tabs.second.form.linkedin.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.linkedin}>
                  {formErrors.linkedin}
                </ErrorContainer>
              </InputContainer>
            </Column>
            <Column>
              <InputContainer>
                <Label htmlFor="cv-input">{t("contact-us.tabs.second.form.curiculum.text")}</Label>
                <CustomFileInput>
                    <Input id="cv-input" type="file" name="cv" accept=".pdf,.doc,.docx" onChange={handleFileChange} onBlur={handleBlur} />
                    <span>{fileName || t("contact-us.tabs.second.form.curiculum.placeholder")}</span>
                </CustomFileInput>
                <ErrorContainer visible={!!formErrors.cv}>
                  {formErrors.cv}
                </ErrorContainer>
              </InputContainer>
              <InputContainer tw="flex-1">
                <Label htmlFor="message-input">{t("contact-us.tabs.first.form.message.text")}</Label>
                <TextArea id="message-input" name="message" placeholder={t("contact-us.tabs.first.form.message.placeholder")} onChange={handleInputChange} onBlur={handleBlur} />
                <ErrorContainer visible={!!formErrors.message}>
                  {formErrors.message}
                </ErrorContainer>
              </InputContainer>
            </Column>
          </TwoColumn>
          <SubmitButton type="submit" value={t("contact-us.tabs.first.form.button")}>
            {t("contact-us.tabs.first.form.button")}
          </SubmitButton>
        </form>
      </div>
    </FormContainer>
  );
};
