import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

const Container = tw.div`flex w-full`;
const SingleColumn = tw.div`mx-auto flex flex-row w-full`;
const Content = tw.div`w-full flex justify-center md:justify-start px-2`;
const TableDiv = tw.div``;

const StyledTable = tw.table` mt-2 text-base leading-loose drop-shadow shadow-2xl`;
const StyledTableHead = tw.thead`border-b-2 bg-primary-700`;
const StyledTableBody = tw.tbody`bg-primary-700 opacity-[80%] md:hover:opacity-100 transition duration-500`;
const Tr = tw.tr`border-2 border-primary-700`;
const Th = tw.th`px-2 font-medium whitespace-nowrap border-b-2 border-primary-700 md:hover:scale-105 transition duration-500 hover:border-white hover:border-r-primary-700 hover:border-l-primary-700`;
const Td = tw.td`text-white md:hover:scale-105 transition duration-500 hover:border-2 border-2 border-primary-700 hover:border-b-white`;

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SingleColumn>
        <Content>
          <TableDiv>
            <StyledTable>
              <StyledTableHead>
                <Tr>
                  <Th>{t("about.tabs.fourth.description.clients.head.key")}</Th>
                  <Th>
                    {t("about.tabs.fourth.description.clients.head.value")}
                  </Th>
                </Tr>
              </StyledTableHead>
              <StyledTableBody>
                <Tr>
                  <Td>
                    {t("about.tabs.fourth.description.clients.first.key")}
                  </Td>
                  <Td>
                    {t("about.tabs.fourth.description.clients.first.value")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {t("about.tabs.fourth.description.clients.second.key")}
                  </Td>
                  <Td>
                    {t("about.tabs.fourth.description.clients.second.value")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {t("about.tabs.fourth.description.clients.third.key")}
                  </Td>
                  <Td>
                    {t("about.tabs.fourth.description.clients.third.value")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {t("about.tabs.fourth.description.clients.fourth.key")}
                  </Td>
                  <Td>
                    {t("about.tabs.fourth.description.clients.fourth.value")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {t("about.tabs.fourth.description.clients.fifth.key")}
                  </Td>
                  <Td>
                    {t("about.tabs.fourth.description.clients.fifth.value")}
                  </Td>
                </Tr>
              </StyledTableBody>
            </StyledTable>
          </TableDiv>
        </Content>
      </SingleColumn>
    </Container>
  );
};
