import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { motion, AnimatePresence } from "framer-motion";
import GetQuotaForm from "./GetQuotaForm.js";
import WaterDropGrid from "../misc/waterDropGrid.js";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative text-white font-sans min-h-full w-screen py-6 pt-32 z-0 bg-cover bg-no-repeat bg-center mb-10 px-4`; 
const SingleColumn = tw.div`flex flex-col items-center mx-auto text-white min-h-full `;

const BackGround = styled.div`
  ${tw`relative w-[100%] md:w-[90%] rounded-4xl z-10 pt-10 pb-10 `}
  background-color: rgba(37, 99, 235, 0.25);
  box-shadow:  20px -20px 50px #143961,
             -20px 20px 50px #1a4779;
`;
const Content = tw.div`flex flex-col xl:flex-row w-full justify-center drop-shadow-xl py-2 xl:py-0 z-20 min-h-[75vh]`;

const Card = styled(motion.div)((props) => [
  tw`py-4 lg:py-2 flex flex-wrap items-center justify-center lg:w-11/12 z-20 self-center px-6 md:px-12`,
  props.reversed ? tw`flex-row-reverse w-auto` : "flex-row w-auto",
]);
const Details = tw.div`m-0 p-0 justify-center flex flex-col w-full drop-shadow-2xl py-1`;
const Title = tw.h4`text-3xl font-light text-center py-1`;
const Description = tw.p`mt-2 text-base lg:text-base w-full leading-loose tracking-wide text-center py-2`;


const WaterDropGridContainer = styled.div`
  ${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-1/2 lg:bottom-1/2 right-0 lg:right-0 z-40`}
`;
const WaterDropGridContainer2 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-1/2 lg:bottom-1/2 left-0 lg:left-0 z-40`}
`;
const WaterDropGridContainer3 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] top-[90%] lg:top-[90%] left-[44%] lg:left-[44%] z-40`}
`;
export default () => {
  const { t } = useTranslation();
  const contactTabs = [
    {
      image: '',
      title: t("get-quota.heading.title"),
      subtitle: "",
      description: t("get-quota.heading.description"),
      form: <GetQuotaForm/>
    },
  ];
  return (
    <Container>
      <SingleColumn>
      <BackGround>
      <WaterDropGridContainer>
            <WaterDropGrid />
      </WaterDropGridContainer>
      <WaterDropGridContainer2>
            <WaterDropGrid />
      </WaterDropGridContainer2>
      <WaterDropGridContainer3>
            <WaterDropGrid />
      </WaterDropGridContainer3>
        <Content>
          <AnimatePresence mode='wait'>
            <Card
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
                {/* <Image imageSrc={aboutUsTabs[activeTab].image} /> */}
                <Details>
                <Title>{contactTabs[0].title}</Title>
                <Description>{contactTabs[0].description}</Description>
                {contactTabs[0].form}
                </Details>
            </Card>
          </AnimatePresence>
        </Content>
        </BackGround>

      </SingleColumn>
    </Container>
  );
};
