import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/eye.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/settings.svg";
import VirtueComponent from "./VirtueComponent.js";
import { useTranslation } from "react-i18next";

/* const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2 text-primary-500`}
  }
`; */
const Container = tw.div`flex`;
const TwoColumn = tw.div`flex flex-col lg:flex-row`;
const Column = tw.div`flex flex-col`;
const TextColumn = styled(Column)((props) => [
  tw`w-full mt-6 md:mt-0 md:mr-10`,
  props.textOnLeft
    ? tw`order-first`
    : tw`order-last`,
]);

const TextContent = tw.div`text-center lg:text-left`;
const Features = tw.div`mx-auto md:mx-0 flex flex-col w-full px-4`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center w-full text-center flex-col`;
const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-white rounded p-2 flex-shrink-0`}
  ${(props) => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100 hover:text-primary-700 hover:bg-white`,
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;
const FeatureDescription = tw.div`mt-4 text-center text-white leading-relaxed`;



export default ({
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
}) => {
  const { t } = useTranslation();

  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: t("about.tabs.first.description.components.first.title"),
      description:
      t("about.tabs.first.description.components.first.description"),
      iconContainerCss: tw`bg-primary-500 text-white`,
    },
    {
      Icon: MoneyIcon,
      title: t("about.tabs.first.description.components.second.title"),
      description:
      t("about.tabs.first.description.components.second.description"),
      iconContainerCss: tw`bg-primary-500 text-white`,
    },
  ];

  if (!features) features = defaultFeatures;
  return (
    <Container>
      <TwoColumn>
          <VirtueComponent/>
          <TextColumn>
            <TextContent>
              <Features>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureHeadingContainer>
                      <FeatureIconContainer
                        iconFilled={iconFilled}
                        iconRoundedFull={iconRoundedFull}
                        css={feature.iconContainerCss || iconContainerCss}>
                        {<feature.Icon />}
                      </FeatureIconContainer>
                      <FeatureHeading>{feature.title}</FeatureHeading>
                    </FeatureHeadingContainer>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </Feature>
                ))}
              </Features>
            </TextContent>
          </TextColumn>
      </TwoColumn>
    </Container>
  );
};
