import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import { useMemo } from 'react';

const TabContainer = styled(motion.button)`
  ${tw`relative overflow-hidden px-2 py-2 m-2 text-sm font-semibold text-white rounded-md`}
  ${tw`bg-primary-700 border shadow-btn-primary w-72`}
  ${props => props.active && tw`bg-white shadow-btn-white`}

  span {
    ${props => props.active && tw`text-primary-700 font-bold`}
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background: white;
    transform: scaleX(0) scaleY(0);  // Modify this line
    transform-origin: center;
    transition: transform 1s ease-in-out;
    z-index: -1;
    border-radius: 25%;  // Add this line
    min-width: 100%;
  }

  ${props => props.active && `
    &:before {
      transform: scaleX(0) scaleY(0);  // Modify this line
      transform-origin: center;
      transition: transform 1s ease-in;
      background: #17406D;
    }
  `}
`;


const Tab = ({ children, active, onClick, ...rest }) => {
  const animateColor = useMemo(() => ({
      color: active ? "rgba(23, 64, 109, 1)" : "rgba(255, 255, 255, 1)"
  }), [active]);

  return (
    <TabContainer active={active.toString()} onClick={onClick} {...rest}>
      <motion.span
        animate={animateColor}
        transition={{ duration: .5 }}
      >
        {children}
      </motion.span>
    </TabContainer>
  );
};

export default Tab;