import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useParams, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";import Tab from "../misc/Tab.js";
import SimpleContactUs from "./SimpleContactUs";
import ApplyJobForm from "./ApplyJobForm.js";
import WaterDropGrid from "../misc/waterDropGrid.js";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Container = tw.div`relative text-white font-sans min-h-full w-screen py-6 pt-32 z-0 bg-cover bg-no-repeat bg-center mb-10 px-4`; 
const SingleColumn = tw.div`flex flex-col items-center mx-auto text-white min-h-full `;
const BackGround = styled.div`
  ${tw`relative w-[90%] rounded-4xl z-10 pt-10 pb-10 `}
  background-color: rgba(37, 99, 235, 0.25);
  box-shadow:  20px -20px 50px #143961,
             -20px 20px 50px #1a4779;
`;

const Content = tw.div`flex flex-col xl:flex-row w-full drop-shadow-xl py-2 xl:py-0 z-20 min-h-[75vh]`;

const Card = styled(motion.div)((props) => [
  tw`py-4 lg:py-2 flex flex-wrap items-center justify-center lg:w-9/12 z-20 self-center px-12`,
  props.reversed ? tw`flex-row-reverse w-auto` : "flex-row w-auto",
]);
const Details = tw.div`m-0 p-0 justify-center w-full drop-shadow-2xl py-1`;
const Title = tw.h4`text-3xl font-light text-center py-1`;
const Description = tw.p`mt-2 text-base lg:text-base leading-loose tracking-wide text-center py-2`;



const TabContainer = tw.div`flex flex-col self-center lg:flex-col justify-center gap-1 md:gap-2 lg:w-3/12 xl:pl-12 `;
const WaterDropGridContainer = styled.div`
  ${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-[7%] lg:bottom-[7%] left-0 lg:left-0 z-40`}
`;
const WaterDropGridContainer2 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-[70%] lg:bottom-[70%] left-0 lg:left-0 z-40`}
`;
const WaterDropGridContainer3 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] bottom-[80%] lg:bottom-[80%] right-0 lg:right-0 z-40`}
`;


export default ({ activeTab }) => {
  const navigate = useNavigate();
  const tabLinks = [
    "send-message",
    "apply-for-job"
  ]
  const initialTabIndex = tabLinks.indexOf(activeTab);
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex !== -1 ? initialTabIndex : 0);
  const prevActiveTabIndex = useRef(activeTabIndex);
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    prevActiveTabIndex.current = activeTabIndex;
  }, [activeTabIndex]);
  const contactTabs = [
    {
      image: '',
      title: t("contact-us.tabs.first.name"),
      subtitle: "Lorem ipsum",
      description: "",
      form: <SimpleContactUs/>
    },
    {
      image: '',
      title: t("contact-us.tabs.second.name"),
      subtitle: "Lorem ipsum",
      description: "",
      form: <ApplyJobForm/>
    },
  
  ];
  const changeTab = (index) => {
    setActiveTabIndex(index);
    // Update the URL with the appropriate tab link
    navigate(`/${lang}/contact/${tabLinks[index]}`);
  };
  
  return (
    <Container>
      <SingleColumn>
      <BackGround>
      <WaterDropGridContainer>
            <WaterDropGrid />
      </WaterDropGridContainer>
        <WaterDropGridContainer2>
            <WaterDropGrid />
      </WaterDropGridContainer2>
      <WaterDropGridContainer3>
            <WaterDropGrid />
      </WaterDropGridContainer3>
        <Content>
          <TabContainer>
            {contactTabs.map((tab, index) => (
              <Tab
              key={index}
              onClick={() => changeTab(index)}
              active={activeTabIndex === index}
              animate={{
                backgroundColor: activeTabIndex === index ? "rgba(255, 255, 255, 1)" : "transparent",
                color: activeTabIndex === index ? "rgba(23, 64, 109, 1)" : "rgba(255, 255, 255, 1)",
              }}
              transition={{ duration: 0.9 }}
            >
              {tab.title}
            </Tab>
            ))}
          </TabContainer>

          <AnimatePresence mode='wait'>
            <Card
              key={activeTabIndex}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
                {/* <Image imageSrc={aboutUsTabs[activeTab].image} /> */}
                <Details>
                <Title>{contactTabs[activeTabIndex].title}</Title>
                <Description>{contactTabs[activeTabIndex].description}</Description>
                {contactTabs[activeTabIndex].form}
                </Details>
            </Card>
          </AnimatePresence>
        </Content>
        </BackGround>

      </SingleColumn>
    </Container>
  );
};
