import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnDark.js";
import AboutUsComponents from "components/features/AboutUsComponents.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import AuditAssurancec from "../images/section-1.webp";
import i18n from "i18next";
import { useParams } from "react-router-dom";

const StyledHeader = styled(Header)`
  ${tw`text-white`}
`;
const Container = styled.div`
  ${tw`relative text-white font-sans min-h-full z-20 bg-cover bg-no-repeat bg-center `}
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${(props) => props.image});
`;
export default function AboutUsPage() {
  const { lang } = useParams();
  const { tab } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <AnimationRevealPage disabled>
      <Container image={AuditAssurancec}>
      <StyledHeader />
      <AboutUsComponents activeTab={tab}/>
      <Footer />
      </Container>
    </AnimationRevealPage>
  );
}