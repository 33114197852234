import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { LanguageProvider } from "./helpers/LanguageContext.js"; // Import the LanguageProvider

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);

// Wrap the entire application with LanguageProvider
root.render(
  <LanguageProvider>
    <App />
  </LanguageProvider>
);
