import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Tab from "../misc/Tab.js";
import { motion, AnimatePresence } from "framer-motion";
import ThreeColWithSideImage from "./ThreeColWithSideImage.js";
import TeamComponents from "./TeamComponents.js";
import BiographyComponent from "./BiographyComponent.js";
import CredentialsComponents from "./CredentialsComponents.js";
import WaterDropGrid from "../misc/waterDropGrid.js";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import MaziluPhoto from "../../images/mazilu_photo.png"

const Container = tw.div`relative text-white font-sans min-h-full w-screen py-6 pt-32 z-0 bg-cover bg-no-repeat bg-center mb-10 my-0 py-0 sm:px-4`; 
const SingleColumn = tw.div`flex flex-col w-full items-center mx-auto text-white min-h-full `;

const HeadingQuote = tw.h2`py-2 text-xl font-medium text-gray-100 text-center w-full drop-shadow-2xl px-2`;
const HeadingSubDescription1 = tw.p`-mt-2 font-light text-gray-400 text-center w-full drop-shadow-2xl`;
const HeadingSubDescription2 = tw.p`font-light text-gray-300 text-center w-full drop-shadow-2xl pb-2`;
const BackGround = styled.div`
  ${tw`relative w-[80%] rounded-4xl z-10 pt-10 pb-10 backdrop-blur-xl`}
  background-color: rgba(37, 99, 235, 0.25);
  box-shadow:  20px -20px 50px #143961,
             -20px 20px 50px #1a4779;
`;
const HeadingInfoContainer = tw.div`flex flex-col items-center w-full drop-shadow-2xl py-2 z-20 border-b-2 rounded`;
const HeadingDescription = tw.p`px-2 py-2 font-medium text-gray-200 text-center w-full drop-shadow-2xl z-20 text-xl`;

const Content = tw.div`flex flex-col lg:flex-row w-full drop-shadow-xl py-2 lg:py-0 z-20 min-h-[65vh]`;

const Card = styled(motion.div)((props) => [
  tw`py-4 lg:py-6 flex flex-wrap w-full lg:min-h-[1000px] justify-center z-20 lg:pl-20 lg:pr-5 xl:pr-20`,
  props.reversed ? tw`flex-row-reverse w-auto` : "flex-row w-auto",
]);

const InfoHolder = tw.div`flex flex-col md:flex-row`
const DescContainer = tw.div`flex flex-col items-center`
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-20 h-20 bg-cover bg-center rounded-full bg-no-repeat self-center m-2 mt-3`}
`;
const Details = tw.div`md:mt-0 mx-2 sm:mx-4 md:mx-2 lg:mx-4 drop-shadow-2xl py-1`;
const Subtitle = tw.div`lg:tracking-wide text-center font-light text-base lg:text-lg lg:py-4 py-2`;
const Title = tw.h4`text-3xl font-light text-center py-1`;
const Description = tw.div`mt-2 text-base lg:text-base leading-loose tracking-wide text-center py-2`;
const TabContainer = tw.div`flex flex-col self-center lg:self-start lg:pt-32 lg:flex-col gap-1 md:gap-2  lg:w-1/3 lg:pl-12`;
const WaterDropGridContainer = styled.div`
  ${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-0 lg:bottom-0 right-0 lg:right-0 z-40`}
`;
const WaterDropGridContainer2 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] rotate-45 bottom-0 lg:bottom-0 left-0 lg:left-0 z-40`}
`;
/* const WaterDropGridContainer3 = styled.div`
${tw`hidden 2xl:flex absolute bg-transparent opacity-[50%] bottom-1/2 lg:bottom-[30%] left-[5%] lg:left-[5%] z-40`}
`; */
// About us data
 
const AboutUsComponents = ({ activeTab }) => {
  const navigate = useNavigate();
  const tabLinks = [
    "vision-and-mission",
    "our-team",
    "managing-partners-bio",
    "client-credentials"
  ]
  const initialTabIndex = tabLinks.indexOf(activeTab);
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex !== -1 ? initialTabIndex : 0);
  const prevActiveTabIndex = useRef(activeTabIndex);
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    prevActiveTabIndex.current = activeTabIndex;
  }, [activeTabIndex]);

  const aboutUsTabs = [
    {
      image: '',
      tab: t("about.tabs.first.tab"),
      title: t("about.tabs.first.name"),
      subtitle: "",
      description: <ThreeColWithSideImage/>
    },
    {
      image: '',
      tab: t("about.tabs.second.tab"),
      title: t("about.tabs.second.name"),
      subtitle: t("about.tabs.second.description.subtitle"),
      description: <TeamComponents/>
    },
    {
      image: '',
      tab: t("about.tabs.third.tab"),
      title: t("about.tabs.third.name"),
      subtitle: t("about.tabs.third.description.subtitle"),
      description: <BiographyComponent/>
    },
    {
      image: '',
      tab: t("about.tabs.fourth.tab"),
      title: t("about.tabs.fourth.name"),
      subtitle: t("about.tabs.fourth.description.subtitle"),
      description: <CredentialsComponents/>
    },
  ];
  const changeTab = (index) => {
    setActiveTabIndex(index);
    // Update the URL with the appropriate tab link
    navigate(`/${lang}/about/${tabLinks[index]}`);
  };
  return (
    <Container>
      <SingleColumn>
      
      <BackGround>
      <WaterDropGridContainer>
            <WaterDropGrid />
      </WaterDropGridContainer>
      <WaterDropGridContainer2>
            <WaterDropGrid />
      </WaterDropGridContainer2>
      {/* <WaterDropGridContainer3>
            <WaterDropGrid />
      </WaterDropGridContainer3> */}
        <HeadingInfoContainer>
          <HeadingTitle>{t("about.head.title")}</HeadingTitle>
          
          <HeadingQuote>
          {t("about.head.subtitle")}
          </HeadingQuote>
          <InfoHolder>
          <CardImage imageSrc={MaziluPhoto} />
          <DescContainer>
          <HeadingDescription>
          {t("about.head.text1")}
          </HeadingDescription>
          <HeadingSubDescription1>
          {t("about.head.text2")}
          </HeadingSubDescription1>
          <HeadingSubDescription2>
          {t("about.head.text3")}
          </HeadingSubDescription2>
          </DescContainer>
          </InfoHolder>
        </HeadingInfoContainer>
        <Content>
        
          <TabContainer>
            {aboutUsTabs.map((tab, index) => (
                <Tab
                  key={index}
                  onClick={() => changeTab(index)}
                  active={activeTabIndex === index}
                  animate={{
                    backgroundColor: activeTabIndex === index ? "rgba(255, 255, 255, 1)" : "transparent",
                    color: activeTabIndex === index ? "rgba(23, 64, 109, 1)" : "rgba(255, 255, 255, 1)",
                  }}
                  transition={{ duration: 0.9 }}
                >
                  {tab.tab}
                </Tab>
              ))}
          </TabContainer>

          <AnimatePresence mode='wait'>
            <Card
              key={activeTabIndex}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
                {/* <Image imageSrc={aboutUsTabs[activeTab].image} /> */}

              <Details>
                <Subtitle>{aboutUsTabs[activeTabIndex].subtitle}</Subtitle>
                <Title>{aboutUsTabs[activeTabIndex].title}</Title>
                <Description>{aboutUsTabs[activeTabIndex].description}</Description>
              </Details>
            </Card>
          </AnimatePresence>
        </Content>
        </BackGround>

      </SingleColumn>
    </Container>
  );
};

export default AboutUsComponents;
