import React, { useEffect, Suspense  } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import SaaSProductLandingPage from "main/SaaSProductLandingPage.js";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ContactUs from "pages/ContactUs";
import Services from "pages/Services";
import AboutUsPage from "pages/AboutUs";
import GetQuota from "pages/GetQuota";
import PrivacyPolicy from "pages/PrivacyPolicy";
import i18n from "i18next";
import { initReactI18next, I18nextProvider  } from "react-i18next";
import en from "./languages/en.json";
import ro from "./languages/ro.json";

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      en: { translation: en },
      ro: { translation: ro },
    },
    lng: ["en", "ro"],
    whitelist: ['en', 'ro'],
    fallbackLng: ['en'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    interpolation: {
      escapeValue: false, // react is already safe from xss
    },
    useSuspense: false,
  });
  
export default function App() {
  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <I18nextProvider i18n={i18n}>
      <GlobalStyles />
      <Suspense>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/en" />} />
          <Route path={`/:lang/*`} element={<SaaSProductLandingPage />} />
          <Route path={`/:lang/contact`} element={<ContactUs />} />
          <Route path={`/:lang/contact/:tab`} element={<ContactUs />} />
          <Route path={`/:lang/get-quote`} element={<GetQuota />} />
          <Route path={`/:lang/services/`} element={<Services />} />
          <Route path={`/:lang/services/:tab`} element={<Services />} />
          <Route path={`/:lang/about/`} element={<AboutUsPage />} />
          <Route path={`/:lang/about/:tab`} element={<AboutUsPage />} />
          <Route path={`/:lang/privacy-policy`} element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      </Suspense>
    </I18nextProvider>
  );
}