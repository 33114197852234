import React, { useState } from "react";
import tw from "twin.macro";
import { ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled(ContainerBase)`
${tw`relative w-[100%] rounded-4xl z-10 hover:transition-all delay-[100ms] overflow-hidden`}
background-color: rgba(37, 99, 235, 0.25);
box-shadow: 20px -20px 50px #143961, -20px 20px 50px #1a4779;

::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 64, 109, 1);
  border-radius: inherit;
  z-index: -1;
  transform-origin: center;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

:hover::before {
  transform: scale(1);
  
}
`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`;
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`;
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`;

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-white`;
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`;
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`;
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`;

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`;
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-primary-500 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`;
const Button = tw(
  PrimaryButton
)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-white text-primary-700 hocus:bg-primary-500 hocus:text-white border border-primary-500 hocus:border-white`;
const SuccessMessage = tw.p`mt-4 text-white text-center`;

export default () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const domain = "maziluandpartners.com";
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://crm.ecomweb.ro/api/subscribe-newsletter/",
        {
          email,
          domain,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setSuccessMessage(t("newsletter.form.success"));
      setEmail("");
      // Handle the response here, e.g., show a success message or update the state
    } catch (err) {
      console.error(t("newsletter.form.error"), err);
      // Handle the error here, e.g., show an error message
    }
  };
  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>{t("newsletter.head")}</Heading>
              <Description>{t("newsletter.description")}</Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <Form onSubmit={handleSubmit}>
              <Input
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("newsletter.form.placeholder")}
                required
              />
              <Button type="submit">{t("newsletter.form.button")}</Button>
            </Form>
            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
          </FormColumn>
        </Row>
      </Content>
    </Container>
  );
};
