import React from "react";
import tw from "twin.macro";

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from "framer-motion";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 overflow-hidden`;
function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = children.map((child, i) => (
    <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
      {child}
    </AnimatedSlideInComponent>
  ));

  return <>{childrenWithAnimation}</>;
}


function AnimatedSlideInComponent({ direction = "left", children }) {
  const x = direction === "left" ? "-150%" : "150%";

  return (
    <motion.section
      initial={{ x }}
      animate={{ x: "0%" }}
      transition={{ type: "spring", damping: 19 }}
    >
      {children}
    </motion.section>
  );
}


export default props => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
);
