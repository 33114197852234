import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import styled from "styled-components";
import AuditAssurancec from "../images/section-3.webp";
import i18n from "i18next";
import { useParams } from "react-router-dom";

const StyledHeader = styled(Header)`
  ${tw`text-white justify-center`}
`;
const Container = styled.div`
  ${tw`relative text-white font-sans min-h-full w-screen z-20 bg-cover bg-no-repeat bg-center`}
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${(props) => props.image});
`;

export default () => {
  const { lang } = useParams();
  const { tab } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []);
  return (
    <AnimationRevealPage disabled={true} className={tw`justify-center`}>
      <Container image={AuditAssurancec}>
      <StyledHeader />
      <ContactUsForm activeTab={tab}/>

      <Footer />
      </Container>

    </AnimationRevealPage>
  );
};
