import React, { useState } from 'react';
import axios from 'axios';
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import logoWhite from "../../images/logo-white.svg";

const FormContainer = styled.div`
  ${tw`w-full mx-auto min-w-full p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const NavLink = tw.a`
  text-sm lg:text-base xl:text-base
  font-semibold tracking-wide transition
  min-w-[150px] p-3 text-center border-b-2 border-primary-700 hocus:border-b-2 duration-200 hocus:border-white border-[#1a4a7d] rounded inline-block
`;
const LogoContainer = tw.div`flex justify-center w-full lg:w-auto`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-light border-b-0 text-xl! hocus:border-0 hocus:shadow-none 
  hocus:bg-transparent`};
  img {
    ${tw`w-10 lg:mr-3 ml-6`}
  }
`;
const LogoNS = tw.div`inline pl-1`;
const LogoName = tw.p`text-base md:text-xl tracking-[2px] md:tracking-[1.5px] px-2`;
const LogoSlogan = tw.p`text-xs md:text-sm px-2`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const ThankYouColumn = tw.div`w-full flex flex-col justify-center min-h-[300px] items-center`;
const Column = tw.div`sm:w-5/12 w-full flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded font-bold tracking-wide shadow-2xl uppercase text-sm transition duration-300 transform hover:text-white border-b-2 hover:border-white hover:bg-primary-700`;
const ErrorContainer = styled.div`
  ${tw`absolute bg-red-500 text-white py-1 px-2 text-xs rounded mt-1`}
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
export default () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    company: "",
    jobTitle: "",
    subject: "",
    message: "",
  };

  const initialFormErrors = {
    name: "",
    email: "",
    phone: "",
    company: "",
    jobTitle: "",
    subject: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const newFormErrors = { ...formErrors };

    if (name === "name" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.name.error");
    } else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      newFormErrors[name] = t("contact-us.tabs.first.form.email.error");
    } else if (name === "phone" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.phone.error");
    } else if (name === "company" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.company.error");
    } else if (name === "jobTitle" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.jobTitle.error");
    } else if (name === "subject" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.subject.error");
    } else if (name === "message" && value.trim() === "") {
      newFormErrors[name] = t("contact-us.tabs.first.form.message.error");
    } else {
      delete newFormErrors[name];
    }

    setFormErrors(newFormErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // New code to check for empty fields
    const newFormErrors = {};
    for (const [key, value] of Object.entries(formData)) {
      if (!value.trim()) {
        newFormErrors[key] = t(`contact-us.tabs.first.form.${key}.error`);
      }
    }
    setFormErrors(newFormErrors);
  
    // Only submit if there are no form errors
    if (Object.keys(newFormErrors).length === 0) {
      try {
        const response = await axios.post("/send/submit-contact.php", formData);
        if (response.status === 200) {
          setIsSubmitted(true);
          setFormData(initialFormData);
        }
      } catch (error) {
        console.error("There was an issue submitting the form:", error);
      }
    } else {
      console.log("Form has errors, not submitting");
    }
  };
  

  if (isSubmitted) {
    return <FormContainer><div tw="mx-auto max-w-4xl justify-center">
    <ThankYouColumn>{t("contact-us.tabs.first.form.thankYou")}</ThankYouColumn></div><LogoContainer>
      <LogoLink href="/">
        <img src={logoWhite} alt="logo" />
        <LogoNS>
          <LogoName>MAZILU & PARTNERS</LogoName>
          <LogoSlogan>Dedication to value creation</LogoSlogan>
        </LogoNS>
      </LogoLink>
    </LogoContainer></FormContainer>;
  }

  return (
    <FormContainer>
      <div tw="mx-auto max-w-4xl">
        <form onSubmit={handleSubmit}>
          <TwoColumn>
            <Column>
              <InputContainer>
                <Label htmlFor="name-input">{t("contact-us.tabs.first.form.name.text")}</Label>
                <Input id="name-input" type="text" name="name" value={formData.name} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.name.placeholder")} />
                <ErrorContainer visible={!!formErrors.name}>{formErrors.name}</ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="email-input">{t("contact-us.tabs.first.form.email.text")}</Label>
                <Input id="email-input" type="email" name="email" value={formData.email} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.email.placeholder")} />
                <ErrorContainer visible={!!formErrors.email}>{formErrors.email}</ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="phone-input">{t("contact-us.tabs.first.form.phone.text")}</Label>
                <Input id="phone-input" type="phone" name="phone" value={formData.phone} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.phone.placeholder")} />
                <ErrorContainer visible={!!formErrors.phone}>{formErrors.phone}</ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="company-input">{t("contact-us.tabs.first.form.company.text")}</Label>
                <Input id="company-input" type="text" name="company" value={formData.company} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.company.placeholder")} />
                <ErrorContainer visible={!!formErrors.company}>{formErrors.company}</ErrorContainer>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="job-title-input">{t("contact-us.tabs.first.form.jobTitle.text")}</Label>
                <Input id="job-title-input" type="text" name="jobTitle" value={formData.jobTitle} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.jobTitle.placeholder")} />
                <ErrorContainer visible={!!formErrors.jobTitle}>{formErrors.jobTitle}</ErrorContainer>
              </InputContainer>
            </Column>
            <Column>
              <InputContainer>
                <Label htmlFor="subject-input">{t("contact-us.tabs.first.form.subject.text")}</Label>
                <Input id="subject-input" type="text" name="subject" value={formData.subject} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.subject.placeholder")} />
                <ErrorContainer visible={!!formErrors.subject}>{formErrors.subject}</ErrorContainer>
              </InputContainer>
              <InputContainer tw="flex-1">
                <Label htmlFor="message-input">{t("contact-us.tabs.first.form.message.text")}</Label>
                <TextArea id="message-input" name="message" value={formData.message} onChange={handleInputChange} onBlur={handleBlur} placeholder={t("contact-us.tabs.first.form.message.placeholder")} />
                <ErrorContainer visible={!!formErrors.message}>{formErrors.message}</ErrorContainer>
              </InputContainer>
            </Column>
          </TwoColumn>
          <SubmitButton type="submit">{t("contact-us.tabs.first.form.button")}</SubmitButton>
        </form>
      </div>
    </FormContainer>
  );
};
