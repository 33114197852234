import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import { SectionHeading } from "components/misc/Headings";
import i18n from "i18next";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";



const ContainerlStyled = tw(Container)`bg-primary-700`;
const ContentWithPaddingXlStyled = tw(ContentWithPaddingXl)`pt-32!`
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-white mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-white`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <AnimationRevealPage disabled>
      <Header />
      <ContainerlStyled>
      <ContentWithPaddingXlStyled>
          <HeadingRow>
            <Heading>{t("privacy-policy.heading")}</Heading>
          </HeadingRow>
          <Text>
            <p>{t("privacy-policy.data.last_updated")}</p>
            <h2>{t("privacy-policy.data.introduction.title")}</h2>
            {t("privacy-policy.data.introduction.points", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}

            <h2>{t("privacy-policy.data.personal_info_collection.title")}</h2>
            {t("privacy-policy.data.personal_info_collection.points", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            
            <ul>
            {t("privacy-policy.data.personal_info_collection.details", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
              </ul>
              <p>{t("privacy-policy.data.personal_info_collection.disclosure")}</p>

              <h2>{t("privacy-policy.utilizarea.title")}</h2>

            <p>{t("privacy-policy.utilizarea.intro")}</p>
            <ul>
            {t("privacy-policy.utilizarea.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            {t("privacy-policy.utilizarea.post", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            </ul>

            <h2>{t("privacy-policy.divulgarea.title")}</h2>
            {t("privacy-policy.divulgarea.intro", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            <ul>
            {t("privacy-policy.divulgarea.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            <p>{t("privacy-policy.divulgarea.post")}</p>
            </ul>

            <h2>{t("privacy-policy.transferuri.title")}</h2>
            
            <ul>
            {t("privacy-policy.divulgarea.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
            

            <h2>{t("privacy-policy.personal_info_keep.title")}</h2>
            {t("privacy-policy.personal_info_keep.intro", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            <ul>
            {t("privacy-policy.personal_info_keep.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
              
            <h2>{t("privacy-policy.personal_info_security.title")}</h2>
            {t("privacy-policy.personal_info_security.intro", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}

            <h2>{t("privacy-policy.modifications.title")}</h2>
            <p>{t("privacy-policy.modifications.intro")}</p>
            <h2>{t("privacy-policy.drepturi.title")}</h2>
            <p>{t("privacy-policy.drepturi.intro")}</p>
            <ul>
            {t("privacy-policy.drepturi.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
            {t("privacy-policy.drepturi.post", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            <h2>{t("privacy-policy.site-uri-terte.title")}</h2>
            {t("privacy-policy.site-uri-terte.points", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
            <h2>{t("privacy-policy.actualizarea.title")}</h2>
            <p>{t("privacy-policy.actualizarea.intro")}</p>

<h2>{t("privacy-policy.cookie-uri.title")}</h2>
{t("privacy-policy.cookie-uri.intro", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
<ul>
            {t("privacy-policy.cookie-uri.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
<p>{t("privacy-policy.cookie-uri.block.intro")}</p>
<ul>
            {t("privacy-policy.cookie-uri.block.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
<p>{t("privacy-policy.cookie-uri.block.outro")}</p>
<p>{t("privacy-policy.cookie-uri.delete.intro")}</p>
<ul>
            {t("privacy-policy.cookie-uri.delete.points", { returnObjects: true }).map((point, index) => (
                        <li><p key={index}>{point}</p></li>
                    ))}
            </ul>
            <p>{t("privacy-policy.cookie-uri.impact")}</p>
            <h2>{t("privacy-policy.actualizari.title")}</h2>
            {t("privacy-policy.actualizari.points", { returnObjects: true }).map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}

          </Text>
        </ContentWithPaddingXlStyled>
        <Footer />
      </ContainerlStyled>
      
    </AnimationRevealPage>
  );
};
