import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";
import { BsWhatsapp } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Container = styled.div`
${tw`relative text-white w-full backdrop-blur-sm`}
background-color: rgba(37, 99, 235, 0.25);`;
const Content = tw.div`mx-auto pb-8 w-full`;
const FiveColumns = tw.div`flex flex-wrap justify-evenly px-16 py-8 gap-0`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-left break-words px-1 md:px-0`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const CopyrightAndCompanyInfoRow = tw.div`pb-2 pt-2 px-2 text-sm font-normal flex flex-col sm:flex-row justify-between items-center -mb-8`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div`pr-4`;
const LogoNS = tw.div`inline pl-1`;
const LogoName = tw.p`tracking-[3px]`;
const LogoSlogan = tw.p`text-sm`;
const Divider = tw.div`border-b-2 border-white w-full opacity-50`;
export default () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <Container>
      <Content>
        <Divider />
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoNS>
          <LogoName>{t("brand.name")}</LogoName>
          <LogoSlogan>{t("brand.slogan")}</LogoSlogan>
          </LogoNS>
            </LogoContainer>
            <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/profile.php?id=61550741141594&sk=about" target="blank">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/maziluandpartners" target="blank">
                <LinkedInIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>{t("menu.services")}</ColumnHeading>
            <LinkList>
            {t("footer.menus.services", { returnObjects: true }).map((point, index) => {
                const link = t("footer.menus.services-links", { returnObjects: true })[index];
                return (
                  <LinkListItem key={index}>
                    <Link href={`/${lang}/services/${link}`}>
                      {point}
                    </Link>
                  </LinkListItem>
                );
              })}
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t("menu.about")}</ColumnHeading>
            <LinkList>
              {t("footer.menus.about-us", { returnObjects: true }).map((point, index) => {
                const link = t("footer.menus.about-us-links", { returnObjects: true })[index];
                return (
                  <LinkListItem key={index}>
                    <Link href={`/${lang}/about/${link}`}>
                      {point}
                    </Link>
                  </LinkListItem>
                );
              })}
            </LinkList>
          </Column>
          <Column>
          <ColumnHeading>{t("menu.partners")}</ColumnHeading>
            <LinkList>
            {t("footer.menus.partners", { returnObjects: true }).map((point, index) => (
                        <LinkListItem key={index}><Link><a href={`https://${point}`} target="_blank" rel="noopener noreferrer">{point}</a> </Link></LinkListItem>
                    ))}
            </LinkList>
          </Column>
          <Column>
          <ColumnHeading>{t("menu.contact")}</ColumnHeading>
            <LinkList>
            {t("footer.menus.contact", { returnObjects: true }).map((point, index) => (
                        <LinkListItem key={index}><Link>{point}</Link></LinkListItem>
                    ))}
                    <LinkListItem><SocialLink href="https://wa.me/40767573157"><BsWhatsapp/></SocialLink></LinkListItem>
                    <LinkListItem><Link href={`/${lang}/privacy-policy`}>{t("footer.menus.privacy-policy")}</Link></LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>
            &copy; {t("footer.copy")}
          </CopyrightNotice>
          <CompanyInfo>{t("footer.powered")} <Link href="www.ecomweb.ro"> Ecomweb</Link> </CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
